import { HorizontalNav } from "../components/HorizontalNav";

export default function Contact() {
  return (
    <>
      <HorizontalNav />
      <div class="contact-container">
        <h1>Get In Touch!</h1>
        <div class="contact-info">
          <p>For further information about the TOSS system, please reach out to us at:</p>
          <p>Maria Carthon</p>
          <p><strong>Email:</strong> <a href="mailto: tosssystems@gmail.com">tosssystems@gmail.com</a></p>
          <p><strong>Phone:</strong> <a href="tel:+17706346596">(770) 634-6596</a></p>
        </div>
      </div>
    </>
  );
}
