import * as React from 'react';
import { HamburgerNav } from "./HamburgerNav";
import { Title } from './Title';
import landingVideo from '../videos/landingVideo.mp4'

export function LandingScreen() {

  return (
    <div id="landingScreen">
      <video className='landingVideo' src={landingVideo} type='video/mp4' autoPlay loop muted />
      <Title/>
      <HamburgerNav className = "hamburgerNav"/>
    </div>
  )
  
}
