import { HorizontalNav } from "../components/HorizontalNav";
import { Journey } from "../components/Journey";

export default function About() {
  return (
    <>
      <HorizontalNav />
      <div className="about">
        <Journey/>
      </div>
    </>
  );
}
