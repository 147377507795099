import * as React from 'react';
import { Link } from "react-router-dom"

export function HorizontalNav() {

  const homeScrollToTop = () => {
    if (window.location.pathname === '/' || window.location.pathname === '/Home') {
      window.scrollTo(0, 0);
    }
  }

  return (
    <div className="horizontalNav">
        <Link to="/Home" onClick={homeScrollToTop}>
          <div className="imageContainer"/>
        </Link>
      <div className="horizontalLinks">
        <Link to="/About">About</Link>
        <Link to="/Questions">Questions</Link>
        <Link to="/Contact">Contact</Link>
      </div>
    </div>
  )

}
