import * as React from 'react';
import tiktok from "../videos/youngCoach.mp4"
import daboSwinney from "../images/DaboSwinney.gif"
import { Carousel } from './Carousel';
import { CarouselItem } from './CarouselItem';

export function Problem() {

  return (
    <div id="problem">
      <div>
        <h4 className="subTitle problem">The Problem</h4>
      </div>
      <Carousel>
        <CarouselItem>
          <img src={daboSwinney} width="712" height="400" controls background="black" alt="Coach Dabo Swinney attempts to call a timeout right in front of the refferee"/>
        </CarouselItem>
        <CarouselItem>
          <iframe width="712" height="400" src="https://www.youtube.com/embed/oRxRigZMwKM?si=our58IAJal9aQYRN" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen>
          </iframe>
        </CarouselItem>
        <CarouselItem>
        <iframe width="712" height="400" src="https://www.espn.com/core/video/iframe/_/id/21201017/endcard/false"  title="ESPN video player" allowFullScreen frameBorder="0"></iframe>        
        </CarouselItem>
        <CarouselItem>
          <iframe width="712" height="400" src="https://www.youtube.com/embed/FT7vi91CIjk" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" allowFullScreen>
          </iframe>
        </CarouselItem>
        <CarouselItem>
          <iframe width="712" height="400" src="https://www.youtube.com/embed/dDlCa4IoPvE" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" allowFullScreen>
          </iframe>
        </CarouselItem>
        <CarouselItem>
          <iframe width="712" height="400" src="https://www.youtube.com/embed/nnY501c0hPQ" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" allowFullScreen>
          </iframe>
        </CarouselItem>
        <CarouselItem>
          <div className="verticalVideo">
            <video src={tiktok} width="712" height="400" controls background="black"/>
          </div>
        </CarouselItem>
      </Carousel>
    </div>
  )

}
