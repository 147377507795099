import * as React from 'react';
import * as THREE from 'three';
import { DRACOLoader } from 'three/addons/loaders/DRACOLoader.js';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import coachProduct from '../animations/TOSS_CCenter.glb';

export function Coach() {
    const canvasCoach = React.useRef(null); // To reference the canvas

    React.useEffect(() => {
        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 5000);
        const renderer = new THREE.WebGLRenderer({
            canvas: canvasCoach.current,
            antialias: true,
            alpha: true,
        });
        renderer.setSize(window.innerWidth, window.innerHeight);

        // Ambient light (softens shadows)
        const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
        scene.add(ambientLight);

        //Upper three lights
        const light = new THREE.DirectionalLight(0xffffff, 1);
        light.position.set(2, 5, 4);
        scene.add(light);

        const light1 = new THREE.DirectionalLight(0xffffff, 1);
        light1.position.set(2, 5, 3);
        scene.add(light1);

        const light2 = new THREE.DirectionalLight(0xffffff, 1);
        light2.position.set(2, 5, 3.5);
        scene.add(light2);
        
        //Bottom three lights
        const light3 = new THREE.DirectionalLight(0xffffff, 1);
        light3.position.set(2, -5, 4);
        scene.add(light3);

        const light4 = new THREE.DirectionalLight(0xffffff, 1);
        light4.position.set(2, -5, 3);
        scene.add(light4);

        const light5 = new THREE.DirectionalLight(0xffffff, 1);
        light5.position.set(2, -5, 3.5);
        scene.add(light5);

        // Load models
        const draco = new DRACOLoader();
        draco.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/');

        const loader = new GLTFLoader();
        loader.setDRACOLoader(draco);

        let loadedCItem = null;
        let isDragging = false;
        let previousMousePosition = { x: 0, y: 0 };

        loader.load(coachProduct, (gltf) => {
            loadedCItem = gltf.scene;
            loadedCItem.scale.set(1.5, 1.5, 1.5); // Adjust scale
            loadedCItem.position.set(0, -1, 0); // Position towards the bottom

            scene.add(loadedCItem);
        }, undefined, (error) => {
            console.error('Error loading Coach Item:', error);
        });

        // Camera position
        camera.position.z = 5;

        // Handle rotation with dragging
        const onDocumentMouseMove = (event) => {
            if (isDragging && loadedCItem) {
                const deltaX = event.clientX - previousMousePosition.x;
                const deltaY = event.clientY - previousMousePosition.y;
                
                const rotationSpeed = 0.005;
                loadedCItem.rotation.y += deltaX * rotationSpeed;
                loadedCItem.rotation.x += deltaY * rotationSpeed;

                previousMousePosition = { x: event.clientX, y: event.clientY };
            }
        };

        const onDocumentMouseDown = (event) => {
            isDragging = true;
            previousMousePosition = { x: event.clientX, y: event.clientY };
        };

        const onDocumentMouseUp = () => {
            isDragging = false;
        };

        // Add event listeners for dragging rotation
        window.addEventListener('mousemove', onDocumentMouseMove);
        window.addEventListener('mousedown', onDocumentMouseDown);
        window.addEventListener('mouseup', onDocumentMouseUp);

        function animate() {
            requestAnimationFrame(animate);
            renderer.render(scene, camera);
        }
        animate();

        return () => {
            window.removeEventListener('mousemove', onDocumentMouseMove);
            window.removeEventListener('mousedown', onDocumentMouseDown);
            window.removeEventListener('mouseup', onDocumentMouseUp);
        };
    }, []);

    return (
        <div id="coachProduct">
            <div className='coachInfo'>
                <h6 className='coachTitle'>COACH</h6>
                <section className='coachSubject'>TIMEOUT</section>
                <section className='coachSubject'>BATTERY</section>
                <section className='coachSubject'>WEARABLE</section>
                <section className='coachSubject'>LED DISPLAY</section>
            </div>
            <div className='canvasContainer'>
                <canvas ref={canvasCoach} id="coachCanvas" width="100%" height="100%" />
            </div>
        </div>
    );
}
