import * as React from 'react';
import animatedIntro from "../videos/TOSSAnimated.mp4";

export function IntroVideo() {
    const videoRef = React.useRef(null);
    const observerRef = React.useRef(null);

    React.useEffect(() => {
        const videoElement = videoRef.current;

        const handleIntersect = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    videoElement.play(); // Play the video when in view
                } else {
                    videoElement.pause(); // Pause when out of view
                }
            });
        };

        observerRef.current = new IntersectionObserver(handleIntersect, {
            threshold: 0.5, // Adjust this value to control when the video plays/pauses
        });

        if (videoElement) {
            observerRef.current.observe(videoElement);
        }

        return () => {
            if (observerRef.current && videoElement) {
                observerRef.current.unobserve(videoElement);
            }
        };
    }, []);

    return (
        <div id='introVideo'>
            <div className='introVideoContainer'>
                <video
                    ref={videoRef}
                    src={animatedIntro}
                    width="70%"
                    controls
                    style={{ background: "black" }}
                    muted // Mute video for autoplay
                ></video>
            </div>
        </div>
    );
}
