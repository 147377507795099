import * as React from 'react';
import * as THREE from 'three';
import { DRACOLoader } from 'three/addons/loaders/DRACOLoader.js';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import refProduct from '../animations/TOSS_RCenter_compressed.glb';

export function Referee() {
    const canvasRef = React.useRef(null); // To reference the canvas

    React.useEffect(() => {
        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
        const renderer = new THREE.WebGLRenderer({
            canvas: canvasRef.current,
            antialias: true,
            alpha: true,
        });
        renderer.setSize(window.innerWidth, window.innerHeight);

        // Ambient light (softens shadows)
        const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
        scene.add(ambientLight);

        //Upper three lights
        const light = new THREE.DirectionalLight(0xffffff, 1);
        light.position.set(2, 5, 4);
        scene.add(light);

        const light1 = new THREE.DirectionalLight(0xffffff, 1);
        light1.position.set(2, 5, 3);
        scene.add(light1);

        const light2 = new THREE.DirectionalLight(0xffffff, 1);
        light2.position.set(2, 5, 3.5);
        scene.add(light2);
        
        //Bottom three lights
        const light3 = new THREE.DirectionalLight(0xffffff, 1);
        light3.position.set(2, -5, 4);
        scene.add(light3);

        const light4 = new THREE.DirectionalLight(0xffffff, 1);
        light4.position.set(2, -5, 3);
        scene.add(light4);

        const light5 = new THREE.DirectionalLight(0xffffff, 1);
        light5.position.set(2, -5, 3.5);
        scene.add(light5);

        // Load models
        const draco = new DRACOLoader();
        draco.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/');

        const loader = new GLTFLoader();
        loader.setDRACOLoader(draco);

        let loadedRItem = null;
        let isDragging = false;
        let previousMousePosition = { x: 0, y: 0 };

        loader.load(refProduct, (gltf) => {
            loadedRItem = gltf.scene;
            loadedRItem.scale.set(2, 2, 2); // Adjust scale
            loadedRItem.position.set(0, 0, 0); // Position towards the bottom
            loadedRItem.traverse((child) => {
                if (child.isMesh) {
                    if (child.material && child.material.name === 'Toss_Wrist_Letters_Glow') {
                        child.material.transparent = false; // Disable transparency
                        child.material.alphaTest = 0.5; // Alpha threshold for transparency
                    }
                    child.castShadow = false; // Disable shadow casting
                    child.receiveShadow = false; // Disable receiving shadows
                }
            });

            scene.add(loadedRItem);
        }, undefined, (error) => {
            console.error('Error loading Referee Item:', error);
        });

        // Camera position
        camera.position.z = 5;

        // Handle rotation with dragging
        const onDocumentMouseMove = (event) => {
            if (isDragging && loadedRItem) {
                const deltaX = event.clientX - previousMousePosition.x;
                const deltaY = event.clientY - previousMousePosition.y;
                
                const rotationSpeed = 0.005;
                loadedRItem.rotation.y += deltaX * rotationSpeed;
                loadedRItem.rotation.x += deltaY * rotationSpeed;

                previousMousePosition = { x: event.clientX, y: event.clientY };
            }
        };

        const onDocumentMouseDown = (event) => {
            isDragging = true;
            previousMousePosition = { x: event.clientX, y: event.clientY };
        };

        const onDocumentMouseUp = () => {
            isDragging = false;
        };

        // Add event listeners for dragging rotation
        window.addEventListener('mousemove', onDocumentMouseMove);
        window.addEventListener('mousedown', onDocumentMouseDown);
        window.addEventListener('mouseup', onDocumentMouseUp);

        function animate() {
            requestAnimationFrame(animate);
            renderer.render(scene, camera);
        }
        animate();

        return () => {
            window.removeEventListener('mousemove', onDocumentMouseMove);
            window.removeEventListener('mousedown', onDocumentMouseDown);
            window.removeEventListener('mouseup', onDocumentMouseUp);
        };
    }, []);

    return (
        <div id="refProduct">
            <div className='canvasContainer'>
                <canvas ref={canvasRef} id="refCanvas"/>
            </div>
            <div className='refInfo'>
                <h6 className='refTitle'>REFEREE</h6>
                <section className='refSubject'>TIMEOUT</section>
                <section className='refSubject'>BATTERY</section>
                <section className='refSubject'>WEARABLE</section>
                <section className='refSubject'>LED DISPLAY</section>
            </div>
        </div>
    );
}
